import React, { Component } from 'react';

export default class Spinner extends Component {
  render() {
    return (

        <div className="loader">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            viewBox="25 25 50 50"
            className="svg-loader"
          >
            <circle
              cx="50"
              cy="50"
              r="20"
              fill="none"
              strokeWidth="5"
              stroke="#00b6ef"
              strokeLinecap="round"
              strokeDashoffset="0"
              strokeDasharray="100, 200"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="2.5s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="stroke-dashoffset"
                values="0;-30;-124"
                dur="1.25s"
                repeatCount="indefinite"
              />
              <animate
                attributeName="stroke-dasharray"
                values="0,200;110,200;110,200"
                dur="1.25s"
                repeatCount="indefinite"
              />
            </circle>
          </svg>
        </div>
    );
  }
}
